import { Row, Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPlay } from '@fortawesome/free-solid-svg-icons';
import XInput from '../../../component/form/XInput';
import XSelect from '../../../component/form/XSelect';
import XButton from '../../../component/button/XButton';
import useUser from './useUser';
import { userStatusOptions } from '../../../utils/options';
import XBackButton from '../../../component/button/XBackButton';
import useRoles from '../../../hooks/useRoles';
import useSidebar from '../../../hooks/useSidebar';
import PhoneNumberInput from '../../../component/phoneInput/phoneInput';

const AddUser = () => {
  const { formik, countries, states, id, isLoading } = useUser()
  const { roles } = useRoles()
  const { onShowSidebar } = useSidebar();

  return (
    <div>
      <Form onSubmit={formik.handleSubmit} noValidate>
        <div className="main-title ">
          <h2 className='back-text'>
            <span className="d-lg-none cursor-pointer" onClick={()=>onShowSidebar()}><FontAwesomeIcon icon={faBars} /></span>{id ? 'Update' : 'Add'} User</h2>
        </div>
        <div className="add-contact">
          <Row>
            {!id && (<Col xl={4} md={6} xs={12}>
              <XInput label='Email Address' name='email' placeholder='Enter' type='email' formik={formik} />
            </Col>)}

            <Col xl={4} md={6} xs={12}>
              <XInput label="First Name" name="first_name" placeholder="Enter" type="text" formik={formik} />
            </Col>
            <Col xl={4} md={6} xs={12}>
              <XInput label="Last Name" name="last_name" placeholder="Enter" type="text" formik={formik} />
            </Col>
            <Col xl={4} md={6} xs={12}>
              <PhoneNumberInput
                value={formik.values.phone_number}
                onChange={phone => formik.setFieldValue('phone_number', phone)}
                error={formik.errors.phone_number}
                touched={formik.touched.phone_number}
              />
            </Col>
            <Col xl={4} md={6} xs={12}>
              <XInput label="Address" name="address" placeholder="Enter" type="text" formik={formik} />
            </Col>
            <Col xl={4} md={6} xs={12}>
              <XInput label="ZIP Code" name="zipcode" placeholder="Enter" type="text" formik={formik} />
            </Col>
            <Col xl={4} md={6} xs={12}>
              <XSelect
                name='country_id'
                label="Country"
                options={Object.values(countries)}
                formik={formik}
              />
            </Col>
            <Col xl={4} md={6} xs={12}>
              <XSelect
                name={`state_id`}
                label="State"
                options={Object.values(states)}
                formik={formik}
              />
            </Col>
            <Col xl={4} md={6} xs={12}>
              <XInput name={`city`} label="City" formik={formik} placeholder="Enter" type="text"/>
            </Col>
            <Col xl={4} md={6} xs={12}>
              <XSelect
                name={`status_id`}
                label="Status"
                options={userStatusOptions}
                formik={formik}
              />
            </Col>
            <Col xl={4} md={6} xs={12}>
              <XSelect
                name={`role_id`}
                label="Role"
                options={Object.values(roles)}
                formik={formik}
              />
            </Col>
          </Row>
        </div>
        <div className="btns">
          <div className='d-flex justify-content-between align-items-center'>
            <XBackButton />
            <XButton loading={isLoading} type='submit' buttonText={<span>Submit<FontAwesomeIcon icon={faPlay} /></span>} className='btn-theme' />
          </div>
        </div>
      </Form>
    </div>
  )
}

export default AddUser
