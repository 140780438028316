/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode, useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faPlay, faTag } from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import * as Yup from "yup";
import CreatableSelect from "react-select/creatable";
import { API_ENDPOINTS } from "../../constants/api";
import useAxios from "../../hooks/useAxios";
import { useLocation } from "react-router-dom";
import { toaster } from "../../utils/toast";
import { useNavigate } from "react-router-dom";
import { NavDropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { mergeTags } from "../../component/stripo/utils/stripo.constant";
import { CampaignType } from "../../utils/enums";
import useSidebar from "../../hooks/useSidebar";

const CreateSmsContent: React.FC = () => {
  const [folders, setFolders] = useState<any>({});
  const { fetchData, postData } = useAxios();
  const location = useLocation();
  const { editTemplateData } = location.state || {};
  const navigate = useNavigate();
  const smsContentRef = useRef<HTMLTextAreaElement>(null);
  const { onShowSidebar } = useSidebar();

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Name is required"),
    selectedFolder: Yup.array().min(1, "Folder selection is required"),
    content: Yup.string().trim().required("SMS content is required"),
  });

  const formik = useFormik<any>({
    initialValues: {
      name: editTemplateData?.name ?? "",
      selectedFolder: editTemplateData?.name ?? [],
      content: editTemplateData?.content ?? "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any) => {
      const id = editTemplateData?.id ?? "";
      const url = id
        ? API_ENDPOINTS.EDIT_TEMPLATE
        : API_ENDPOINTS.CREATE_TEMPLATE;
      const payload = {
        name: values.name,
        folder_id: values.selectedFolder[0]?.value ?? "",
        ...(id && { id }),
        content: values.content,
        type: CampaignType.SMS,
      };
      const response = await postData(url, payload);
      const { message, success } = response;
      if (response.success) {
        formik.resetForm();
        navigate("/org/content/sms-content-library");
      }
      toaster({ message, success });
    },
  });

  useEffect(() => {
    fetchFolders();
  }, []);

  useEffect(() => {
    if (editTemplateData?.name)
      formik.setFieldValue("name", editTemplateData?.name ?? "");
    if (editTemplateData?.folder_id) {
      const editFolder = [folders[editTemplateData?.folder_id]];
      formik.setFieldValue("selectedFolder", editFolder ?? []);
    }
  }, [editTemplateData?.id, folders]);

  const fetchFolders = async () => {
    try {
      const type = CampaignType.SMS;
      const response = await fetchData(
        `${API_ENDPOINTS.GET_ALL_FOLDERS}/${type}`
      );
      const formattedOption = response?.data.reduce((acc: any, v: any) => {
        acc[v.id] = {
          value: v.id,
          text: v.name,
          label: v.name,
          key: v.name,
          data: v,
        };
        return acc;
      }, {});
      setFolders(formattedOption);
    } catch (error) {}
  };

  const handleFolderCreate = async (inputValue: string) => {
    const payload = {
      name: inputValue,
      type: CampaignType.SMS,
    };
    const response = await postData(API_ENDPOINTS.CREATE_FOLDER, payload);
    if (response.success) {
      const savedData = response.data;
      const obj = {
        value: savedData.id,
        text: savedData.name,
        label: savedData.name,
        key: savedData.name,
        data: savedData,
      };
      setFolders({
        ...folders,
        [savedData.id]: obj,
      });
      formik.setFieldValue("selectedFolder", [obj]);
    }
  };

  const insertAtCursor = (value: any) => {
    const textarea = smsContentRef.current;
    if (!textarea) return;
    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;
    const newText =
      formik.values.content.substring(0, startPos) +
      value +
      formik.values.content.substring(endPos, formik.values.content.length);

    formik.setFieldValue("content", newText);
  };

  return (
    <div>
      <div className="main-title d-flex align-items-center justify-content-between">
        <h2 className="d-flex align-items-center">
          <span className="d-lg-none cursor-pointer" onClick={()=>onShowSidebar()}>
            <FontAwesomeIcon icon={faBars} />
          </span>
          Create SMS Content 
        </h2>
      </div>
      <div className="add-contact">
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col xl={6} md={6} xs={12}>
              <Form.Group className="form-group" controlId="formBasicEmail">
                <Form.Label>Name Your Template</Form.Label>
                <Form.Control
                  type="text"
                  {...formik.getFieldProps("name")}
                  placeholder="Name"
                  isInvalid={formik.touched.name && !!formik.errors.name}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="text-danger error-text">
                    {formik.errors.name as ReactNode}
                  </div>
                )}
              </Form.Group>
            </Col>
            <Col xl={6} md={6} xs={12}>
              <Form.Group
                className="form-group select-folder-dropdown"
                controlId="formBasicEmail"
              >
                <Form.Label>Select Folder</Form.Label>

                <CreatableSelect
                  {...formik.getFieldProps("selectedFolder")}
                  options={Object.values(folders)}
                  onCreateOption={(inputValue) =>
                    handleFolderCreate(inputValue)
                  }
                  placeholder="Type here to create folder..."
                  value={formik.values.selectedFolder}
                  onChange={(selected) => {
                    formik.setFieldValue("selectedFolder", [selected]);
                  }}
                />
                {formik.touched.selectedFolder &&
                  formik.errors.selectedFolder && (
                    <div className="text-danger error-text">
                      {formik.errors.selectedFolder as ReactNode}
                    </div>
                  )}
              </Form.Group>
            </Col>
            <Col xl={12} md={12} xs={12}>
              <Form.Group className="form-group" controlId="formBasicEmail">
                <div className="d-flex mb-2 align-items-center">
                  <Form.Label className="mb-0">
                    Enter Template Message
                  </Form.Label>
                  <div className="profile d-flex ps-3 tag-dropdown">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id="tooltip-bottom">
                          Add dynamic value
                        </Tooltip>
                      }
                    >
                      <NavDropdown
                        title={<FontAwesomeIcon icon={faTag} fontSize="25px" />}
                        id="navbarScrollingDropdown"
                        align="end"
                      >
                        {mergeTags.map((tag: any) => {
                          if (tag.type.includes(CampaignType.SMS)) {
                            return (
                              <div key={tag.value}>
                                <NavDropdown.Item
                                  onClick={() => insertAtCursor(tag.value)}
                                >
                                  {tag.label}
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                              </div>
                            );
                          }
                        })}
                      </NavDropdown>
                    </OverlayTrigger>
                  </div>
                </div>
                <Form.Control
                  as="textarea"
                  ref={smsContentRef}
                  placeholder="Type message here..."
                  style={{ minHeight: "200px" }}
                  {...formik.getFieldProps("content")}
                  isInvalid={formik.touched.content && !!formik.errors.content}
                />
                <div className="d-flex justify-content-between">
                  {formik.touched.content && formik.errors.content && (
                    <div className="w-100 text-danger error-text">
                      {formik.errors.content as ReactNode}
                    </div>
                  )}
                  <p
                    className={`text-end w-100 error-text ${
                      formik.values.content.length > 160 ? "text-danger" : ""
                    }`}
                  >
                    {formik.values.content.length}/160
                  </p>
                </div>
              </Form.Group>
            </Col>
          </Row>

          <div className="d-flex justify-content-between align-items-center">
            <Button
              variant="primary"
              className="btn-theme btn-border"
              onClick={() => navigate("/org/content/sms-content-library")}
            >
              <FontAwesomeIcon icon={faPlay} />
              Back
            </Button>
            <Button
              variant="primary"
              type="submit"
              className="btn-theme"
              disabled={formik.values.content.length > 250}
            >
              Submit
              <FontAwesomeIcon icon={faPlay} />
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CreateSmsContent;
