import React from "react";
import { Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

interface PhoneNumberInputProps {
  value: string;
  onChange: (phone: string) => void;
  error?: any;
  touched?: any;
  label?: string;
  placeholder?: string;
  country?: string;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  value,
  onChange,
  error,
  touched,
  label = "Phone Number",
  placeholder = "Enter phone number",
  country = "us",
}) => {
  return (
    <Form.Group
      className="form-group custom-tel-input"
      controlId="formBasicPhoneNumber"
    >
      <Form.Label>{label}</Form.Label>
      <PhoneInput
        country={country}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      {touched && error && (
        <Form.Text className="text-danger error-text">{error}</Form.Text>
      )}
    </Form.Group>
  );
};

export default PhoneNumberInput;
