/* eslint-disable react-hooks/exhaustive-deps */
import React ,{useEffect,ReactNode} from 'react'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars,  faPlay, } from '@fortawesome/free-solid-svg-icons';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { addContactRequest, clearData, contactDetailRequest, getTagsRequest } from '../../../redux/modules/contacts/contactSlice';
import { RootState } from '../../../redux/rootReducer';
import { toast } from 'react-toastify';
import { useNavigate,useParams } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import Loader from '../../../component/loader';
import {  FormValues, Option } from '../../../redux/modules/auth/types';
import { locationRequest } from '../../../redux/modules/auth/locationSlice';
import useAxios from '../../../hooks/useAxios';
import { API_ENDPOINTS } from '../../../constants/api';
import CustomFields from './customFields';
import useLocationData from '../../../hooks/useLocationData';
import XSelect from '../../../component/form/XSelect';
import useSidebar from '../../../hooks/useSidebar';
import { toaster } from '../../../utils/toast';
import { getPredefinedFieldRequest } from '../../../redux/modules/settings/settingsSlice';
import PhoneNumberInput from '../../../component/phoneInput/phoneInput';

const AddContact:React.FC = () => {
    const { contactId } = useParams();
    const dispatch = useDispatch();
    const navigate=useNavigate();
    const [selectedOption, setSelectedOption] = useState<Option[] | null>(null);
    const {addedContact,contactdetails,editedContact,error,loading}= useSelector((state: RootState) => state.contactlist);
    const { predefinedFieldList }= useSelector((state: RootState) => state.settings);
    const { tags } = useSelector((state: RootState) => state.contactlist.tags);
    const [selectedState, setSelectedState] = useState<number>();
    const {  postData } = useAxios();
    const AddContactValidationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is required'),
        // firstName: Yup.string().required('First Name is required'),
        // lastName: Yup.string().required('Last Name is required'),
        // address: Yup.string().required('Enter your street address'),
        // city: Yup.string().required('Select your city'),
        // state: Yup.string().required('Select your state'),
        // zipCode: Yup.string()
        // .matches(/^[0-9]{5}$/, 'ZIP Code must be a 5-digit number')
        // .required('Enter your ZIP Code'),
        // country: Yup.string().required('Select your country'),
        // phoneNumber: Yup.string()
        // .matches(/^\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})$/, 'Phone number must only contain digits')
        // .required('Enter your phone number'),
        // dob: Yup.date().required('Date of Birth is required'),
        // tags: Yup.array().min(1, 'Select at least one tag'),
      });
    const [customFieldPayload, setCustomFieldPayload] = useState<any>({});
    const { countries, states, fetchStates } = useLocationData();
    const { onShowSidebar } = useSidebar();
    const today = new Date().toISOString().split('T')[0];
     
      const formik = useFormik<FormValues>({
        initialValues: {
            email: '',
            firstName: '',
            lastName: '',
            address: '',
            city: '',
            state: '',
            zipCode: '',
            country: '',
            phoneNumber: '',
            dob: '',
            tags: [],
          },
        validationSchema:AddContactValidationSchema,
        onSubmit: (values:FormValues) => {
          const payload={
            email: values.email,
            firstname: values.firstName,
            lastname: values.lastName,
            address: values.address,
            city:values.city,
            state:values.state,
            zipcode: values.zipCode,
            country: values.country,
            phone_number: values.phoneNumber.length > 3 ? `+${values.phoneNumber}` : "",
            DOB: values.dob,
            tags:selectedOption || [],
          }
          let data = {...payload, customFieldPayload}
          dispatch(addContactRequest({data, contactId}));
        },
      });
      useEffect(() => {
        dispatch(getTagsRequest());
    }, [dispatch]);

    useEffect(() => {
      dispatch(getPredefinedFieldRequest());
    }, []);

    useEffect(() => {
      if (formik.values.country) {
        fetchStates(formik.values.country);
        formik.setFieldValue('state', '');
      }
    }, [formik.values.country]);

      useEffect(() => {
        if (addedContact && addedContact.success === true) {
          toast(addedContact.message);
          navigate('/org/contacts/allContacts')
        }
        else if (error && error.success === false) {
          toaster({message: error.message, success: false})
        }
        if (editedContact && editedContact.success === true) {
          toast(editedContact.message);
          navigate('/org/contacts/allContacts')
        }
        dispatch(clearData());
      }, [error, addedContact,editedContact])

      useEffect(() => {
        dispatch(locationRequest());
        if(contactId){
          dispatch(contactDetailRequest(contactId))
        }
      }, [contactId]);

      useEffect(() => {
            if (loading) {
              return;
            }
            if(contactId){
                if (contactdetails && contactdetails?.contact) {
                    const {
                        email,
                        firstname,
                        lastname,
                        address,
                        city,
                        state,
                        zipcode,
                        country,
                        phone_number,
                        DOB,
                        tags,
                        custom_fields_values
                      } = contactdetails.contact;
                      const selectedTags = tags.map((tag:any) => ({
                        label: tag.name,
                        value: tag.id,
                      }));
                      const utcDate = new Date(DOB);
                      const year = utcDate.getFullYear();
                      const month = String(utcDate.getMonth() + 1).padStart(2, '0');
                      const day = String(utcDate.getDate()).padStart(2, '0');
                      const formattedDateString = DOB === null ? DOB :`${year}-${month}-${day}`;
                      formik.setValues({
                          email,
                          firstName: firstname,
                          lastName: lastname,
                          address,
                          city,
                          state,
                          zipCode: zipcode,
                          country,
                          phoneNumber: phone_number,
                          dob:formattedDateString,
                          tags: selectedTags, 
                      });
                      setSelectedOption(selectedTags);
                      const prefilledCustomValue: { [key: string]: { name: string; value: any } } = {};
                      for (const value of custom_fields_values) {
                        if (value?.custom_field_id) {
                          prefilledCustomValue[value?.custom_field_id] = {
                            name: value?.custom_field_name,
                            value: value?.value
                          }
                        }
                      }
                      setCustomFieldPayload(prefilledCustomValue)
                const selectedStateId = parseInt(state, 10);
                setSelectedState(selectedStateId);
                }
            }          
      }, [contactdetails]);

      const handleCreateOrFindTag = async (tagName: string) => {
        const payload={
          tagName:tagName
        } 
        const response = await postData(API_ENDPOINTS.CREATE_OR_FIND_TAG, payload);
        if(response?.success){
              const newTag = { label: tagName, value: response.tagId.id};
              formik.setFieldValue('tags', [...(selectedOption || []), newTag]);
              setSelectedOption([...(selectedOption || []), newTag]);
        }   
      };

  return (
    <div>
          <div className="main-title ">
            <h2 className='back-text'>
              <span className="d-lg-none cursor-pointer" onClick={()=>onShowSidebar()}><FontAwesomeIcon icon={faBars} /></span>{contactId? 'Edit Contact' : 'Add Contact'}</h2>
          </div>
          <div className="add-contact">
            <Form onSubmit={formik.handleSubmit} >
              <Row>
                <Col xl={4} md={6} xs={12}>
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control type="email"
                     placeholder="Enter" 
                     {...formik.getFieldProps('email')}
                     />
                <Form.Text className="text-danger error-text">{formik.touched.email && formik.errors.email}</Form.Text>

                  </Form.Group>
                </Col>
                <Col xl={4} md={6} xs={12}>
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control type="text"
                     placeholder="Enter" 
                     {...formik.getFieldProps('firstName')}
                    />
                 <Form.Text className="text-danger error-text">{formik.touched.firstName && formik.errors.firstName}</Form.Text>
                  </Form.Group>
                </Col>
                <Col xl={4} md={6} xs={12}>
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control 
                    type="text" placeholder="Enter" 
                    {...formik.getFieldProps('lastName')}
                    />
                   <Form.Text className="text-danger error-text">{formik.touched.lastName && formik.errors.lastName}</Form.Text>
                  </Form.Group>
                </Col>
                <Col xl={8} md={6} xs={12}>
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                     type="text" placeholder="Enter" 
                     {...formik.getFieldProps('address')}
                     />
                 <Form.Text className="text-danger error-text">{formik.touched.address && formik.errors.address}</Form.Text>
                  </Form.Group>
                </Col>
                <Col xl={4} md={6} xs={12}>
                  <Form.Group className="form-group" controlId="exampleForm.ControlInput1">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                     type="text" placeholder="Enter" 
                     {...formik.getFieldProps('city')}
                     />
                    <Form.Text className='text-danger error-text'>{formik.touched.city && formik.errors.city}</Form.Text>
                  </Form.Group>
                </Col>
                <Col xl={4} md={6} xs={12}>
                  <XSelect
                    name='country'
                    label="Country"
                    options={Object.values(countries)}
                    formik={formik}
                  />
                </Col>
                <Col xl={4} md={6} xs={12}>
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>ZIP Code</Form.Label>
                    <Form.Control
                     type="text" placeholder="Enter" 
                     {...formik.getFieldProps('zipCode')}
                    />
                <Form.Text className='text-danger error-text'>{formik.touched.zipCode && formik.errors.zipCode}</Form.Text>
                  </Form.Group>
                </Col>
                <Col xl={4} md={6} xs={12}>
                <XSelect
                    name='state'
                    label="State"
                    options={Object.values(states)}
                    formik={formik}
                  />
                </Col>
                <Col xl={4} md={6} xs={12}>
                  <PhoneNumberInput
                    value={formik.values.phoneNumber}
                    onChange={phone => formik.setFieldValue('phoneNumber', phone)}
                    error={formik.errors.phoneNumber}
                    touched={formik.touched.phoneNumber}
                  />
                </Col>
                <Col xl={4} md={6} xs={12}>
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>DOB</Form.Label>
                    <Form.Control type="date" placeholder="Enter"
                    {...formik.getFieldProps('dob')}
                    value={formik.values.dob}
                    max={today}
                     />
                    <Form.Text className="text-danger error-text">{formik.touched.dob && formik.errors.dob}</Form.Text>

                  </Form.Group>
                </Col>
                <Col xl={4} md={6} xs={12}>
                <Form.Group className="form-group" controlId="formBasicEmail">
                    <Form.Label>Tags</Form.Label>

                     <CreatableSelect
                      isMulti
                      {...formik.getFieldProps('tags')}  
                      value={selectedOption}
                      options={tags && tags?.map((tag:any) => ({ label: tag.name, value: tag.id }))}
                      onChange={(selected) => {
                        formik.setFieldValue('tags', selected);
                        setSelectedOption(selected as Option[] | null);
                      }}
                      onCreateOption={(inputValue:any) => { 
                        handleCreateOrFindTag(inputValue)
                      }}
          />
                {formik.touched.tags && formik.errors.tags && (
                <div className="text-danger error-text">
                  {formik.errors.tags as ReactNode}
                </div>
)}

                  </Form.Group>
                </Col>
                <CustomFields
                  customFieldPayload={customFieldPayload}
                  setCustomFieldPayload={setCustomFieldPayload}
                  customFields={predefinedFieldList.customFields}
                />
              </Row>
              <br/>
              {/* <div className="btns"> */}
            <div className='d-flex justify-content-between align-items-center'>
              <Button variant="primary" className='btn-theme btn-border'   onClick={() => navigate('/org/contacts/allContacts')}>
                <FontAwesomeIcon icon={faPlay} />Back
              </Button>
              <Button variant="primary" type="submit" className='btn-theme'  >
              {loading && <Loader/>}
                Submit<FontAwesomeIcon icon={faPlay} />
              </Button>
            </div>
          {/* </div> */}
            </Form>
          </div>
    </div>
  )
}

export default AddContact